import * as React from "react";
import { useEffect } from "react";
import {
  createMachine,
  assign,
  ActionObject,
  ActionFunction,
  Sender,
} from "xstate";
import { useMachine } from "@xstate/react";

import {
  spritesheetJson,
  spritesheetPng,
  CharacterAnimation,
  CharacterAnimationName,
} from "../assets";

import { Sprite, SpriteProps } from "./Sprite";

interface CharacterAnimationContext {
  frame: number;
  animation: CharacterAnimation;
  loop: boolean;
}

interface PlayingContext extends CharacterAnimationContext {}

type CharacterAnimationState =
  | { value: "playing"; context: PlayingContext }
  | { value: "paused"; context: CharacterAnimationContext };

const IncrementFrame = "IncrementFrame" as const;
const Initialize = "Initialize" as const;

const ScheduleTick = "ScheduleTick" as const;

interface TickEvent {
  type: "TICK";
}
interface PlayEvent
  extends Pick<CharacterAnimationContext, "animation" | "loop"> {
  type: "PLAY";
}
type CharacterAnimationEvent = TickEvent | PlayEvent;

export const characterAnimationMachine = createMachine<
  // am I really bad at this or is xstate not meant to be used in TS?
  // The types of context from the "Typestate" generic parameter are unused
  CharacterAnimationContext,
  CharacterAnimationEvent,
  CharacterAnimationState
>(
  {
    id: "character animation",
    initial: "paused",
    context: {
      frame: 0,
      animation: {
        length: 0,
        totalDuration: 0,
        frames: [],
      },
      loop: false,
    },
    states: {
      playing: {
        invoke: { src: ScheduleTick },
        on: {
          TICK: [
            {
              target: "playing",
              cond: (ctx) => ctx.animation.length - 1 > ctx.frame,
              actions: [IncrementFrame],
            },
            {
              target: "paused",
              cond: (ctx) => !ctx.loop && ctx.animation.length - 1 <= ctx.frame,
            },
          ],
        },
      },
      paused: {
        on: {
          PLAY: {
            target: "playing",
            actions: [Initialize],
          },
        },
      },
    },
  },
  {
    services: {
      [ScheduleTick]: (ctx) => (cb: Sender<TickEvent>) => {
        const { animation, frame } = ctx as PlayingContext;
        const current = animation.frames[frame];
        const timeout = setTimeout(() => cb("TICK"), current.duration);

        return () => clearInterval(timeout);
      },
    },
    actions: {
      [Initialize]: assign((_, e: PlayEvent) => ({
        animation: e.animation,
        loop: e.loop,
      })),
      [IncrementFrame]: assign<PlayingContext>({
        frame: (ctx: PlayingContext) => {
          if (ctx.animation.length - 1 <= ctx.frame + 1) {
            return ctx.loop ? 0 : ctx.animation.length - 1;
          }
          return ctx.frame + 1;
        },
      }),
    } as Record<
      string,
      | ActionObject<CharacterAnimationContext, CharacterAnimationEvent>
      | ActionFunction<CharacterAnimationContext, CharacterAnimationEvent>
    >,
  }
);

export function useCharacterSprite(
  name: CharacterAnimationName,
  { loop = false }: { loop?: boolean } = {}
) {
  const animation: CharacterAnimation = spritesheetJson[name];
  const [state, send] = useMachine(characterAnimationMachine, {
    devTools:
      // DevTools can't be prerendered
      typeof window !== "undefined",
  });

  useEffect(() => {
    send({ type: "PLAY", animation, loop });
  }, [animation, loop, send]);

  return {
    name,
    state,
    send,
  };
}
export type UseCharacterSpriteResult = ReturnType<typeof useCharacterSprite>;

export interface CharacterSpriteProps
  extends Pick<UseCharacterSpriteResult, "state" | "name">,
    Omit<SpriteProps, "src" | "alt" | "bounds" | "pixelRatio"> {}

export function CharacterSprite({
  state,
  name,
  ...rest
}: CharacterSpriteProps) {
  return (
    <Sprite
      src={spritesheetPng}
      alt={name.replace("_", " ")}
      bounds={
        state.context.animation.frames[
          state.matches("playing") ? state.context.frame : 0
        ]
      }
      {...rest}
    />
  );
}
