/* eslint-disable no-param-reassign */
/** @jsx jsx */
import React, { ComponentProps } from "react";
import { range, findIndex } from "fp-ts/lib/Array";
import { Flex, jsx } from "theme-ui";
import { randomInt, randomBool } from "fp-ts/lib/Random";
import { getOrElse } from "fp-ts/lib/Option";

import topdownTilesetData from "../assets/tilesets/topdown.json";
import tileset from "../assets/tilesets/topdown.png";
import { Sprite } from "./Sprite";
import { BSPTreeLeaf, BSPTree } from "../logic/bspTree";
import { getSmallestRectangleEnclosing } from "../logic/getSmallestRectangleEnclosing";

type TilesetData = {
  floor: {
    begin: { x: number; y: number };
    width: number;
    height: number;
  };
  tileSize: number;
};

const getRandomTilePosition = (tilesetData: TilesetData) => {
  const { tileSize, floor } = tilesetData;
  const { begin, width, height } = floor;

  return {
    x: (randomInt(0, width - 1)() + begin.x) * tileSize,
    y: (randomInt(0, height - 1)() + begin.y) * tileSize,
  };
};

type Position = { x: number; y: number };
type Size = { w: number; h: number };

const minRoomDimensions = { w: 7, h: 7 };
// const maxRoomDimensions = { w: 14, h: 14 };

const createBspTree = (position: Position, size: Size): BSPTree => {
  const { x, y } = position;
  const { w, h } = size;

  if (w <= minRoomDimensions.w * 2 && h > minRoomDimensions.h * 2) {
    const topChildHeight = randomInt(
      minRoomDimensions.h,
      h - minRoomDimensions.h
    )();

    return {
      x,
      y,
      w,
      h,
      t: createBspTree({ x, y }, { w, h: topChildHeight }),
      b: createBspTree(
        { x, y: y + topChildHeight },
        { w, h: h - topChildHeight }
      ),
    };
  }

  if (w > minRoomDimensions.w * 2 && h <= minRoomDimensions.h * 2) {
    const leftChildWidth = randomInt(
      minRoomDimensions.w,
      w - minRoomDimensions.w
    )();

    return {
      x,
      y,
      w,
      h,
      l: createBspTree({ x, y }, { w: leftChildWidth, h }),
      r: createBspTree(
        { x: x + leftChildWidth, y },
        { w: w - leftChildWidth, h }
      ),
    };
  }

  if (w > minRoomDimensions.w * 2 && h > minRoomDimensions.h * 2) {
    const direction = randomBool() ? "horizontal" : "vertical";

    if (direction === "horizontal") {
      // We don't want to get rectangles smaller than minWidth x minHeight
      const topChildHeight = randomInt(
        minRoomDimensions.h,
        Math.max(h - minRoomDimensions.h, minRoomDimensions.h)
      )();

      return {
        x,
        y,
        w,
        h,
        t: createBspTree({ x, y }, { w, h: topChildHeight }),
        b: createBspTree(
          { x, y: y + topChildHeight },
          { w, h: h - topChildHeight }
        ),
      };
    }

    // We don't want rectangles of width lower than 5
    const leftChildWidth = randomInt(
      minRoomDimensions.w,
      Math.max(w - minRoomDimensions.w, minRoomDimensions.w)
    )();

    return {
      x,
      y,
      w,
      h,
      l: createBspTree({ x, y }, { w: leftChildWidth, h }),
      r: createBspTree(
        { x: x + leftChildWidth, y },
        { w: w - leftChildWidth, h }
      ),
    };
  }

  return {
    x,
    y,
    w,
    h,
  };
};

type RoomsHull = BSPTreeLeaf;

// FIXME: For some reason the generated map has ALWAYS 2 void tiles on the right and
// 2 void tiles on the bottom.
const dfs = (tree: BSPTree, map: string[][]): RoomsHull => {
  // leaf
  if (!("l" in tree) && !("t" in tree)) {
    console.assert(
      tree.w >= minRoomDimensions.w,
      `leaf width is smaller than ${minRoomDimensions.w}`
    );
    console.assert(
      tree.h >= minRoomDimensions.h,
      `leaf height is smaller than ${minRoomDimensions.w}`
    );

    const randomHeight = randomInt(
      // What's up with the "-2"? It's here, because we want to create a space around the room, so
      // they'll be only connected with the corridors.
      Math.min(minRoomDimensions.h, tree.h - 2),
      tree.h - 2
    )();

    const randomWidth = randomInt(
      Math.min(minRoomDimensions.w, tree.w - 2),
      tree.w - 2
    )();

    const heightDifference = tree.h - randomHeight;
    const widthDifference = tree.w - randomWidth;

    const actualRoomY = tree.y + Math.floor(heightDifference / 2);
    const actualRoomHeight = randomHeight;

    const actualRoomX = tree.x + Math.floor(widthDifference / 2);
    const actualRoomWidth = randomWidth;

    range(actualRoomY, actualRoomY + actualRoomHeight - 1).forEach((row) =>
      range(actualRoomX, actualRoomX + actualRoomWidth - 1).forEach((col) => {
        map[row][col] = "r";
      })
    );

    return {
      x: actualRoomX,
      y: actualRoomY,
      w: actualRoomWidth,
      h: actualRoomHeight,
    };
  }

  if ("l" in tree) {
    const leftHull = dfs(tree.l, map);
    const rightHull = dfs(tree.r, map);

    console.assert(leftHull.x > 0, `leftHull.x = ${leftHull.x}`);

    // A segment of Y axis which is enclosed in left and right hull.
    // Needed to calculate the possible places from which we can
    // grow a corridor between two rooms.
    const sharedHeight = {
      start: Math.max(leftHull.y, rightHull.y),
      end: Math.min(leftHull.y + leftHull.h - 1, rightHull.y + rightHull.h - 1),
    };

    const corridorY = randomInt(sharedHeight.start, sharedHeight.end - 1)();
    const row = map[corridorY];

    // Search for the first non-void tile going left from leftHull.x + leftHull.w - 1.
    const corridorStart =
      leftHull.x +
      leftHull.w -
      1 -
      getOrElse(() => {
        console.error("Shouldn't be possible to reach that");
        return 0;
      })(
        findIndex((s: string) => s !== " ")(
          row.slice(leftHull.x, leftHull.x + leftHull.w).reverse()
        )
      );

    // Search for the first non-void tile going right from corridorStart
    const corridorEndX =
      getOrElse(() => 0)(
        findIndex((s: string) => s !== " ")(row.slice(corridorStart + 1))
      ) + corridorStart;

    range(corridorStart, corridorEndX).forEach((x) => {
      row[x] = "c";
    });

    return getSmallestRectangleEnclosing(leftHull, rightHull);
  }

  const topHull = dfs(tree.t, map);
  const bottomHull = dfs(tree.b, map);

  // A segment of X axis which is enclosed in top and bottom hull.
  // Needed to calculate the possible places from which we can
  // grow a corridor between two rooms.
  const sharedWidth = {
    start: Math.max(topHull.x, bottomHull.x),
    end: Math.min(topHull.x + topHull.w - 1, bottomHull.x + bottomHull.w - 1),
  };

  // NOTE: What if there's no shared width? Is it even posible in current
  // implementation?

  const corridorX = randomInt(sharedWidth.start, sharedWidth.end)();

  // First, search for a room/corridor tile in corridorX column starting from
  // the bottom of the topHull and going up.
  let corridorY = topHull.y + topHull.h - 1;
  while (corridorY >= topHull.y && map[corridorY][corridorX] === " ") {
    corridorY -= 1;
  }

  // Now, draw a corridor.
  let nextCorridorTileY = corridorY + 1;
  while (
    nextCorridorTileY < bottomHull.y + bottomHull.h &&
    map[nextCorridorTileY][corridorX] === " "
  ) {
    map[nextCorridorTileY][corridorX] = "c";
    nextCorridorTileY += 1;
  }

  return getSmallestRectangleEnclosing(topHull, bottomHull);
};

const generateRooms = (bspTree: BSPTree) => {
  const { w, h } = bspTree;
  const map = range(0, h).map(() => range(0, w).map(() => " "));

  dfs(bspTree, map);

  return map;
};

type MapProps = ComponentProps<"div"> & {
  width: number;
  height: number;
  children: (x: number, y: number) => React.ReactNode;
};

export const GameMap = ({ width, height, children, ...rest }: MapProps) => {
  const tree = createBspTree({ x: 0, y: 0 }, { w: width, h: height });
  const map = generateRooms(tree);

  return (
    <div {...rest}>
      {range(0, map.length - 1).map((row) => (
        <Flex key={row}>
          {range(0, map[0].length - 1).map((col) => (
            <div
              key={`${row}-${col}`}
              sx={{ position: "relative", display: "flex" }}
            >
              {map[row][col] !== " " ? (
                <Sprite
                  src={tileset}
                  alt="floor tile"
                  bounds={{
                    w: 8,
                    h: 8,
                    ...getRandomTilePosition(topdownTilesetData),
                  }}
                  //// sx={{ position: "absolute", top: 0, left: 0 }}
                  // Character sprites are enclosed in 20x20 boxes and are
                  // later multiplied by pixel ratio of 3, so they occupy
                  // 60x60. The tileset tiles are 8x8. We multiply them by 8
                  // to get 64x64 boxes, so we can position characters with
                  // the offset of 2px from every side.
                  pixelRatio={8}
                />
              ) : (
                <Sprite
                  src={tileset}
                  alt="abyss tile"
                  bounds={{ w: 8, h: 8, x: 0, y: 0 }}
                  //// sx={{ position: "absolute", top: 0, left: 0 }} <- this never worked, we need to combine it with translate3d
                  pixelRatio={8}
                />
              )}

              <div sx={{ position: "absolute", top: "2px", left: "2px" }}>
                {children(row, col)}
              </div>
            </div>
          ))}
        </Flex>
      ))}
    </div>
  );
};
