/** @jsx jsx */
import { jsx } from "theme-ui";
// eslint-disable-next-line import/no-extraneous-dependencies
import { css } from "@emotion/core";

import { ComponentProps } from "react";
import { PIXEL_RATIO } from "../gatsby-plugin-theme-ui";

interface Bounds {
  x: number;
  y: number;
  w: number;
  h: number;
}

const pixelatedStyles = (pixelRatio: number) => css`
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  transform: scale(${pixelRatio});
  transform-origin: 0 0;
`;

export interface SpriteProps
  extends Required<Pick<ComponentProps<"img">, "src" | "alt">>,
    Pick<ComponentProps<"img">, "className" | "style"> {
  bounds?: Bounds;
  pixelRatio?: number;
}

export function Sprite({
  bounds,
  pixelRatio = PIXEL_RATIO,
  src,
  alt,
  style,
  className,
}: SpriteProps) {
  return (
    <div
      data-sprite
      className={className}
      style={style}
      css={[
        {
          display: "inline-block",
          width: 20 * pixelRatio,
          height: 20 * pixelRatio,
        },
        bounds && {
          display: "inline-block",
          position: "relative",
          width: bounds.w * pixelRatio,
          height: bounds.h * pixelRatio,
        },
      ]}
    >
      <img
        css={pixelatedStyles(pixelRatio)}
        style={
          bounds && {
            objectFit: "none",
            // This part could possibly be done imperatively for animation
            // if we need to optimize rendering
            objectPosition: `-${bounds.x}px -${bounds.y}px`,
            width: bounds.w,
            height: bounds.h,

            position: "absolute",
            top: 0,
          }
        }
        src={src}
        alt={alt}
      />
    </div>
  );
}
