import { keys } from "fp-ts/lib/ReadonlyRecord";
import { CreateEntity, Entity, System, ComponentsLists } from "./lib";
import { Position } from "./position";
import { Sprite } from "./sprite";

export interface Components {
  position: Position;
  sprite: Sprite;
}

export type ComponentName = keyof Components;
export type Component = Components[ComponentName];

export function makeEcs() {
  const componentsLists: ComponentsLists<Components> = {
    position: [],
    sprite: [],
  };

  let entityId = -1;
  const createEntity: CreateEntity<Components> = (data) => {
    entityId++;
    for (const [name, component] of Object.entries(data)) {
      if (component) {
        componentsLists[name as keyof typeof data][entityId] = component;
      }
    }
    return entityId;
  };

  const removeEntity = (id: Entity) => {
    for (const key of keys(componentsLists)) {
      componentsLists[key][id as number] = componentsLists[key][entityId];
      componentsLists[key].length = entityId;
    }
    entityId--;
  };

  const systems: System<Component[]>[] = [];
  const run = () => {
    /* boop */
  };

  return {
    componentsLists,
    createEntity,
    removeEntity,
    systems,
    run,
  };
}
