import { BSPTreeLeaf } from "./bspTree";

export const getSmallestRectangleEnclosing = (
  rect1: BSPTreeLeaf,
  rect2: BSPTreeLeaf
) => {
  return {
    x: Math.min(rect1.x, rect2.x),
    y: Math.min(rect1.y, rect2.y),
    w:
      Math.max(rect1.x + rect1.w, rect2.x + rect2.w) -
      Math.min(rect1.x, rect2.x),
    h:
      Math.max(rect1.y + rect1.h, rect2.y + rect2.h) -
      Math.min(rect1.y, rect2.y),
  };
};
