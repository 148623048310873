/** @jsx jsx */
import { jsx, Container, SystemStyleObject } from "theme-ui";

import { GameMap } from "../ui/GameMap";
import { Sprite } from "../ui/Sprite";
import {
  CharacterSprite,
  useCharacterSprite,
  CharacterSpriteProps,
} from "../ui/CharacterSprite";

import potionRedPng from "../assets/items/potion_red.png";
import { makeEcs } from "../logic/ecs";
import { Sprite as SpriteComponent } from "../logic/ecs/sprite";

//#region debug
function TileNumber({ x, y }: { x: number; y: number }) {
  return (
    <span style={{ opacity: 0.1 }}>
      {x}, {y}
    </span>
  );
}
//#endregion

const game = makeEcs();
game.createEntity({
  sprite: { characterTypeAndState: "guard_run" },
  position: { x: 2, y: 2 },
});
game.createEntity({ sprite: { src: potionRedPng }, position: { x: 6, y: 6 } });

const Character = ({
  characterTypeAndState,
  ...rest
}: SpriteComponent.CharacterSprite &
  Pick<CharacterSpriteProps, "style" | "className">) => {
  const animation = useCharacterSprite(characterTypeAndState, {
    loop: true,
  });

  return <CharacterSprite {...animation} {...rest} />;
};

// Map regenerates on each frame now, but it doesn't matter :D
const IndexPage = () => {
  return (
    <Container>
      <GameMap width={25} height={20}>
        {(x, y) => <TileNumber x={x} y={y} />}
      </GameMap>
      <div
        className="sprites-layer"
        sx={{ zIndex: 1, position: "absolute", top: 0 }}
      >
        {game.componentsLists.position.map((pos, i) => {
          const sprite = game.componentsLists.sprite[i];
          if (!sprite || !pos) {
            return null;
          }

          const positionSx: SystemStyleObject = {
            position: "absolute",
            top: 0,
            left: 0,
          };
          const positionStyle = {
            transform: `translate3d(${pos.x * 60}px, ${pos.y * 60}px, 0)`,
            backgroundColor: "rgba(255, 255, 255, 0.1)", // temp
          };

          if (sprite.src !== undefined) {
            return (
              <Sprite
                key={i}
                alt=""
                sx={positionSx}
                style={positionStyle}
                {...sprite}
              />
            );
          }
          return (
            <Character
              key={i}
              sx={positionSx}
              style={positionStyle}
              {...sprite}
            />
          );
        })}
      </div>
    </Container>
  );
};

export default IndexPage;
